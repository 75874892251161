<template>

  <!-- Announcement Form -->
  <j-form model="announcements.announcement">

    <!-- Title -->
    <j-control name="title" />

    <!-- Body -->
    <j-control
      name="body"
      rows="20"
    />

    <!-- Stage -->
    <j-control name="stage" cols="6" />

    <!-- Type -->
    <j-control name="section_slug" cols="6" />

  </j-form>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('announcements')

export default {
  name: 'AnnouncementForm',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'announcement'
    ])
  }
}
</script>
