<template>

  <!-- Announcement Edit Panel -->
  <div id="announcement-edit-panel">

    <!-- Announcement Form -->
    <j-card>
      <j-card-text>
        <AnnouncementForm />
      </j-card-text>
    </j-card>

  </div>
</template>

<script>

// Components
import AnnouncementForm from '@/components/Announcements/AnnouncementForm'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('announcements')

export default {
  name: 'AnnouncementEditPanel',
  // -------------
  // Components ==
  // -------------
  components: {
    AnnouncementForm
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'announcement'
    ])
  }
}
</script>
